import axios from "axios";
import {BaseAPI} from "./BaseAPI";
import config from "../config";

export class PublicAPI extends BaseAPI {
  constructor() {
    super(config.apis.public.baseUrl);
  }

  postPatientStatus(status: string, vendor?: string) {
    const url = this.baseUrl + config.apis.public.patientStatus
    let body = {status: status, ...(vendor ? {vendor: vendor} : {})}
    return axios.post(url, body, this.getRequestConfig())
      .then(response => {
        return response
      }).catch(err => {
        console.debug(err);
        throw err;
      })
  }
}

const publicApi = new PublicAPI();
export default publicApi;
