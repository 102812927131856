import React from 'react';
import "./Footer.scss";

export default function PageFooter() {
  return (
    <div className={"footer"} id={"page-footer"}>
      <a className={"footer-link"} href="https://www.nhsggc.scot/patient-visitor-faqs/data-protection-privacy/">Privacy</a>
      <a className={"footer-link"} href="mailto:ggc.mynhsdata@ggc.scot.nhs.uk">Feedback</a>
      <hr/>
      <div>
          © NHS Greater Glasgow & Clyde
      </div>
    </div>
  )
}
