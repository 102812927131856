import React, { useEffect, useState } from "react";
import "./AbbottContainer.scss"
import { AppPropsType } from "../../../App";
import abbott from "../../../services/Abbott";


function ConnectionStatus(props: { data: any }) {
  if (props.data.loading) {
    return (
      <div>
        <h2>Loading...</h2>
        <div>Contacting Abbott servers, please wait.</div>
      </div>
    )
  }

  if (!props.data.connected) {
    console.error(props.data)
    return (
      <div>
        <h2>Not connected</h2>
        <div>Start sharing data with Abbott from the home page.</div>
      </div>
    )
  }

  return (
    <div>
      <h2>Connected successfully</h2>
    </div>
  )
}

export default function AbbottContainer(appProps: AppPropsType) {
  const [statusData, setStatusData] = useState({ loading: "loading" });

  useEffect(() => {
    onLoad();
  }, []);

  function onLoad() {
    abbott.getVendorStatus()
      .catch(err => err)
      .then((data) => setStatusData(data));
  }

  function handleReturnToHomePress() {
    appProps.history.push("/");
  }

  return (
    <div className={"container row fill"}>
      <div className={"offset-sm-4 col-sm-4 centered-section"}>
        <h1>Abbott Status</h1>
        <ConnectionStatus data={statusData}/>
        <button
          id="return-to-home-btn"
          type={"button"}
          className={"secondary-button"}
          onClick={handleReturnToHomePress}
        >
          Return to home
        </button>
      </div>
    </div>
  )
}
