import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "./containers/NotFound/NotFound";
import Login from "./containers/Login/Login";
import UnauthenticatedRoute from "./components/Routers/UnauthenticatedRoute";
import AuthenticatedRoute from "./components/Routers/AuthenticatedRoute";
import AbbottContainer from "./containers/Vendors/Abbott/AbbottContainer"
import AbbottCallbackContainer from "./containers/Vendors/Abbott/AbbottCallbackContainer"
import Home from "./containers/Home/Home";
import Debug from "./containers/Debug/Debug";
import Terms from "./containers/Terms/Terms";
import { AppPropsObject } from "./App";
import { RouteComponentProps } from "react-router";
import ForgotPassword from "./containers/Login/ForgotPassword";
import ChangeEmail from "./containers/ChangeEmail/ChangeEmail";
import ChangePassword from "./containers/ChangePassword/ChangePassword";

const hideDisabledRoutes = true;

const authenticatedRoutes: Record<string, React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>> = {
  "/": Home,
  "/change-password": ChangePassword,
  "/debug": Debug,
  "/Vendors/Abbott": AbbottContainer,
  "/Vendors/Abbott/callback": AbbottCallbackContainer,
};

const unauthenticatedRoutes: Record<string, React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>> = {
  "/forgot-password": ForgotPassword,
  "/login": Login,
};

const publicRoutes: Record<string, React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>> = {
};

const disabledRoutes: Record<string, React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>> = {
  "/change-email": ChangeEmail,
  "/terms": Terms,
}

export default function Routes({ appProps }: AppPropsObject) {
  const _authenticatedRoutes = hideDisabledRoutes ? authenticatedRoutes : { ...authenticatedRoutes, ...disabledRoutes }

  return (
    <Switch>
      {Object.keys(_authenticatedRoutes).map((route) => (
        <AuthenticatedRoute path={route} exact component={_authenticatedRoutes[route]} appProps={appProps} key={route} />
      ))}
      {Object.keys(unauthenticatedRoutes).map((route) => (
        <UnauthenticatedRoute path={route} exact component={unauthenticatedRoutes[route]} appProps={appProps} key={route} />
      ))}
      {Object.keys(publicRoutes).map((route) => (
        <Route path={route} exact component={publicRoutes[route]} appProps={appProps} key={route} />
      ))}

      { /* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  );
}
