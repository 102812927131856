import React, {FormEvent, useState} from "react";
import SubmitButton from "../../components/SubmitButton/SubmitButton";
import {useFormFields, useMobile} from "../../libs/HooksLib";
import ConfirmPassword from "./ConfirmPassword";
import PasswordReset from "./PasswordReset";
import {AuthenticationState, getAuthState, handleInteractiveLogin} from "../../services/HitpLogin";
import {AppPropsType} from "../../App";

export default function Login(appProps: AppPropsType) {
  const [fields, handleFieldChange] = useFormFields({email: "", password: ""});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [authState, setAuthState] = useState(getAuthState());

  function validateForm(): boolean {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  function handleFieldUpdate(event: FormEvent<HTMLInputElement>) {
    setError('');
    handleFieldChange(event);
  }

  async function handleLoginSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);

    try {
      await handleInteractiveLogin(fields.email, fields.password);
    } catch (err) {
      setError(err.message);
      fields.password = '';
    }

    setAuthState(getAuthState())
    setIsLoading(false);

    if (getAuthState() === AuthenticationState.Authenticated)
      appProps.history.push('/');
  }

  const mobile = useMobile();

  function renderLoginForm() {
    return (
      <div className={"container row fill"} id={"login-page"}>
        {!mobile && (
          <div className={"col-sm-6 centered-section"}>
            <h2>Connect your NHS data</h2>
            <div>
              The NHS is committed to embracing digital technology. As part of this commitment, we are providing a
              portal to connect your medical devices directly to your clinical team. If your supported device was
              provided by the NHS, we will allow you to connect your data directly to your clinical team.
            </div>
          </div>
        )}
        <div className={"offset-sm-1 col-sm-4 centered-section"}>
          <h2>Sign in</h2>
          <form onSubmit={handleLoginSubmit}>
            <div>Email</div>
            <input
              id="email"
              autoFocus
              type="text"
              value={fields.email}
              onChange={handleFieldUpdate}
              className={"text-field"}
            />
            <div>Password</div>
            <input
              id="password"
              value={fields.password}
              onChange={handleFieldUpdate}
              className={"text-field"}
              type="password"
            />
            {error !== '' &&
              <span id="error-text" className="form-error">
                {error}
            </span>}
            <SubmitButton
              id="login-btn"
              type="submit"
              isLoading={isLoading}
              disabled={!validateForm()}
              className={"primary-button"}
            >
              Sign in
            </SubmitButton>
            <a className={"float-end"} href={"/forgot-password"}>Forgot password</a>
          </form>
        </div>
      </div>
    );
  }

  return (
    <fieldset className={"fill"}>
      {authState === AuthenticationState.None && renderLoginForm()}
      {authState === AuthenticationState.NewPasswordRequired && <ConfirmPassword {...appProps}/>}
      {authState === AuthenticationState.PasswordResetRequired && <PasswordReset {...appProps} email={fields.email}/>}
    </fieldset>
  )


}
