import SubmitButton from "../../components/SubmitButton/SubmitButton";
import React, {FormEvent, useState} from "react";
import {Auth} from "aws-amplify";
import {useFormFields} from "../../libs/HooksLib";
import {AppPropsType} from "../../App";

export default function ChangePassword(props: AppPropsType) {
  const [fields, handleFieldChange] = useFormFields({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: ""
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("")
  const [passwordChanged, setPasswordChanged] = useState(false);

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);
    if (fields.newPassword === fields.confirmNewPassword) {
      try {
        const user = await Auth.currentAuthenticatedUser()
        await Auth.changePassword(user, fields.currentPassword, fields.newPassword);
        setIsLoading(false);
        setPasswordChanged(true);
      } catch (err) {
        setError(err.message);
        fields.currentPassword = '';
        fields.newPassword = '';
        fields.confirmNewPassword = '';
        setIsLoading(false);
      }
    } else {
      setError("New passwords do not match.");
      setIsLoading(false);
    }
  }

  function handleFieldUpdate(event: FormEvent<HTMLInputElement>) {
    setError('');
    handleFieldChange(event);
  }

  function validateForm(): boolean {
    return (
      fields.currentPassword.length > 0 &&
      fields.newPassword.length > 0 &&
      fields.confirmNewPassword.length > 0
    );
  }

  function handleReturnToHomePress() {
    props.history.push("/");
  }

  function renderForm() {
    return (
      <div className={"offset-sm-4 col-sm-4 centered-section"}>
        <h2>Change password</h2>
        <div className={"subheading"}>Enter your current password and a new password.</div>
        <form onSubmit={handleSubmit} autoComplete={"off"}>
          <div>Current password</div>
          <input
            id="currentPassword"
            className="text-field"
            type="password"
            onChange={handleFieldUpdate}
            value={fields.currentPassword}
          />
          <div>New password</div>
          <input
            id="newPassword"
            className="text-field"
            type="password"
            value={fields.newPassword}
            onChange={handleFieldUpdate}
          />
          <div>Confirm new password</div>
          <input
            id="confirmNewPassword"
            className="text-field"
            type="password"
            value={fields.confirmNewPassword}
            onChange={handleFieldUpdate}
          />
          {error !== '' &&
            <span id="error-text" className="form-error">{error}</span>
          }
          <SubmitButton
            id={"change-password-btn"}
            type="submit"
            isLoading={isLoading}
            disabled={!validateForm()}
            className={"primary-button"}
          >
            Change password
          </SubmitButton>
          <button
            id="return-to-home-btn"
            type={"button"}
            className={"secondary-button"}
            onClick={handleReturnToHomePress}
          >
            Return to home
          </button>
        </form>
      </div>
    )
  }

  function renderSuccessMessage() {
    return (
      <div className={"offset-sm-4 col-sm-4 centered-section"}>
        <h2>Password changed</h2>
        <button
          id="return-to-home-btn"
          type={"button"}
          className={"return-button"}
          onClick={handleReturnToHomePress}
        >
          Return to home
        </button>
      </div>
    )
  }

  return (
    <div className={"row fill"} id={"change-password"}>
      {!passwordChanged ? renderForm() : renderSuccessMessage()}
    </div>
  )
}
