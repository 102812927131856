import {BaseAPI} from "./BaseAPI";
import axios from "axios";
import config from "../config";
import {VendorOauthInitResponse, VendorStatusResponse} from "./ApiTypes";
import publicApi from "./PublicAPI";
import {VendorKeys} from "../constants";


export class Abbott extends BaseAPI {

  constructor() {
    super(config.apis.abbott.baseUrl);
  }

  async processCallback(code: string, state: string){
    const url = new URL(config.apis.abbott.callbackUri, this.baseUrl);
    url.searchParams.append('code', code);
    url.searchParams.append('state', state);

    await axios.get(url.href, this.getRequestConfig())
    await publicApi.postPatientStatus(config.patientStatuses.vendorAuthComplete, VendorKeys.Abbott)
  }

  getVendorStatus(): Promise<VendorStatusResponse> {
    const url = new URL(config.apis.abbott.vendorStatus, this.baseUrl);

    return axios.get(url.href, this.getRequestConfig())
      .then((res) => res.data);

  }

  getVendorOauthInitUrl(): Promise<VendorOauthInitResponse> {
    const url = new URL(config.apis.abbott.oauthInitUrl, this.baseUrl);

    return axios.get(url.href, this.getRequestConfig())
      .then((res) => {
        const url = res.data.auth_url

        return {
          auth_url: url
        }
      });
  }

  async revokeTokens() {
    const url = new URL(config.apis.abbott.revokeTokens, this.baseUrl);

    await axios.post(url.href, {}, this.getRequestConfig());
    await publicApi.postPatientStatus(config.patientStatuses.consentWithdrawn, VendorKeys.Abbott)
  }
}

const abbott = new Abbott();
export default abbott;
