import React, {useEffect, useState} from "react";
import "./Home.scss";
import {AppPropsType} from "../../App";
import abbottLogo from "./../../images/vendor-logos/abbott-logo.png"
// import dexcomLogo from "./../../images/vendor-logos/dexcom-logo.png"
import freeStyleLibreLogo from "./../../images/vendor-logos/freestyle-libre-logo.png"
import abbott from "../../services/Abbott";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import vendorAuthApi from "../../services/VendorAuthAPI";
// import {VendorKeys} from "../../constants";
import Modal from "react-modal";
import {AxiosError, AxiosResponse} from "axios";
import {Route} from "react-router";
import {useMobile} from "../../libs/HooksLib";

export default function Home(props: AppPropsType) {
  const [abbottStatus, setAbbottStatus] = useState("loading");
  // const [dexcomStatus, setDexcomStatus] = useState("loading");
  const [response, setResponse] = useState<AxiosResponse>();
  const [error, setError] = useState<AxiosError>();
  const [redirectUrl, setRedirectUrl] = useState("");
  const [modalHeader, setModalHeader] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalProcessingMessage, setModalProcessingMessage] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalAction, setModalAction] = useState(() => abbottOauthInitUrl);
  const [processingModalResponse, setProcessingModalResponse] = useState(false);

  const vendors = [
    {
      name: "Abbott",
      status: abbottStatus,
      logo: abbottLogo,
      devices: [
        {
          logo: freeStyleLibreLogo
        }
      ],
      oauthInitUrl: abbottOauthInitUrl,
      revokeTokens: abbottRevokeTokens,
    },
    // {
    //   name: "Dexcom",
    //   status: dexcomStatus,
    //   logo: dexcomLogo,
    //   devices: [
    //     {
    //       logo: freeStyleLibreLogo //TODO replace with Dexcom logos if applicable
    //     }
    //   ],
    //   clickHandler: () => vendorRedirectClickHandler(VendorKeys.Dexcom)
    // }
  ]

  useEffect(() => {
    // Closing the modal triggers a request for status
    if (!modalIsOpen)
      updateVendorStatuses();
  }, [modalIsOpen])

  function updateVendorStatuses() {
    setError(undefined);
    setAbbottStatus("loading");
    abbott.getVendorStatus()
      .then((data) => setAbbottStatus(data.connected ? "Sharing" : "Not sharing"))
      .catch(err => {
        console.log(err);
        setError(err);
      })

    // setDexcomStatus("loading");
    // vendorAuthApi.getVendorStatus(VendorKeys.Dexcom)
    //   .catch(err => err)
    //   .then((data) => setDexcomStatus(data.connected ? "Sharing" : "Not sharing"));
  }

  function vendorRedirect() {
    return redirectUrl &&
      <Route component={() => {
        window.location.assign(redirectUrl);
        return null;
      }}/>
  }

  function abbottOauthInitUrl() {
    setResponse(undefined);
    setError(undefined);
    setProcessingModalResponse(true);
    abbott.getVendorOauthInitUrl()
      .then((res) => {
        setRedirectUrl(res.auth_url);
      })
      .catch((err: AxiosError) => {
        console.log(err);
        setError(err);
        closeConsentModal();
        if (err.response) {
          setResponse(err.response);
        }
      })
  }

  async function abbottRevokeTokens() {
    setResponse(undefined);
    setError(undefined);
    setProcessingModalResponse(true);
    await abbott.revokeTokens()
      .catch((err: AxiosError) => {
        console.log(err);
        setError(err);
        if (err.response) {
          setResponse(err.response);
        }
      })
    closeConsentModal();
  }

  // function vendorRedirectClickHandler(vendorKey: string) {  // Used for dexcom connection
  //   setResponse(undefined);
  //   setError(undefined);
  //   vendorAuthApi.getVendorOauthInitUrl(vendorKey)
  //     .then((res) => {
  //       setRedirectUrl(res.auth_url);
  //     })
  //     .catch((err: AxiosError) => {
  //       console.log(err);
  //       setError(err);
  //       if (err.response) {
  //         setResponse(err.response);
  //       }
  //     })
  // }

  function errorContent() {
    return error &&
      <div>
        <h3>Error info:</h3>
        <pre>{JSON.stringify(error!.toJSON(), null, 2)}</pre>
      </div>;
  }

  function responseContent() {
    return response?.data &&
      <div>
        <h3>Response content:</h3>
        <dl>
          {Object.keys(response.data).map(key => {
            return <>
              <dt>{key}</dt>
              <dd>{response.data[key]}</dd>
            </>;
          })}
        </dl>
      </div>;
  }

  function openGiveConsentModal(vendorName: any, clickHandler: any) {
    setModalHeader("Consent Required")
    setModalMessage(`By clicking 'OK' you consent to the third-party vendor ${vendorName} sharing data with NHS GG&C`)
    setModalProcessingMessage("Loading vendor site")
    setModalAction(() => clickHandler);
    setModalIsOpen(true);
  }

  function openRevokeConsentModal(vendorName: any, clickHandler: any) {
    setModalHeader("Withdraw Consent")
    setModalMessage(`By clicking 'OK' you will prevent the third-party vendor ${vendorName} from sharing data with NHS GG&C`)
    setModalProcessingMessage("Withdrawing consent")
    setModalAction(() => clickHandler);
    setModalIsOpen(true)
  }

  function closeConsentModal() {
    setModalIsOpen(false);
    setProcessingModalResponse(false);
  }

  function handleOkClick() {
    modalAction()
  }

  function getVendorTable() {
    return (
      <table className={"table table-bordered vendor-table"} id={"vendor-table"}>
        <thead>
        <tr>
          <th className={"col-sm-3"}>Vendor</th>
          <th className={"col-sm-4"}>Devices</th>
          <th className={"col-sm-3"}>Status</th>
          <th className={"col-sm-2"}></th>
        </tr>
        </thead>
        <tbody>
        {vendors.map((vendor) => {
          return (
            <tr key={vendor.name} id={vendor.name + "-row"}>
              <td>
                <img src={vendor.logo} alt={vendor.name + "-logo"}/>
              </td>
              <td>
                {vendor.devices.map((device) => {
                  return (
                    <img src={device.logo} key={vendor.name + "-device"} alt={vendor.name + "-device"}/>
                  )
                })}
              </td>
              <td>
                {vendor.status === "loading" ?
                  <FontAwesomeIcon className="loading-icon" icon={faSpinner} size={"2x"} spin/> :
                  vendor.status}
              </td>
              <td>
                {vendor.status === "loading" ? (
                  <FontAwesomeIcon className="loading-icon" icon={faSpinner} size={"2x"} spin/>
                ) : vendor.status === "Sharing" ? (
                  //TODO implement stop sharing functionality
                  <button
                    className={"btn btn-link"}
                    onClick={() => openRevokeConsentModal(vendor.name, vendor.revokeTokens)}
                  >
                    Stop sharing
                  </button>
                ) : (
                  <button
                    className={"btn btn-link"}
                    onClick={() => openGiveConsentModal(vendor.name, vendor.oauthInitUrl)}
                  >
                    Start sharing
                  </button>
                )}
              </td>
            </tr>
          )
        })}
        </tbody>
      </table>
    )
  }

  function getMobileVendorTable() {
    return (
      <table className={"table table-bordered vendor-table"} id={"mobile-vendor-table"}>
        <tbody>
        {vendors.map((vendor) => {
          return (
            <tr key={vendor.name} id={vendor.name + "-row"}>
              <div className={"fluid-container"}>
                <div className={"row"}>
                  <div className={"col-6"}>
                    <div>
                      <img src={vendor.logo} alt={vendor.name + "-logo"}/>
                    </div>
                    {vendor.devices.map((device) => {
                      return (
                        <div>
                          <img src={device.logo} key={vendor.name + "-device"} alt={vendor.name + "-device"}/>
                        </div>
                      )
                    })}
                  </div>
                  <div className={"col-6 d-flex flex-column justify-content-around"}>
                    <div className={"d-flex justify-content-end"}>
                      {vendor.status === "loading" ? (
                        <FontAwesomeIcon className="loading-icon" icon={faSpinner} size={"1x"} spin/>
                      ) : vendor.status === "Sharing" ? (
                        <button
                          className={"btn btn-link"}
                          onClick={() => openRevokeConsentModal(vendor.name, vendor.revokeTokens)}
                        >
                          Stop sharing
                        </button>
                      ) : (
                        <button
                          className={"btn btn-link"}
                          onClick={() => openGiveConsentModal(vendor.name, vendor.oauthInitUrl)}
                        >
                          Start sharing
                        </button>
                      )}
                    </div>
                    <div className={"d-flex justify-content-end"}>
                      {vendor.status === "Sharing" && vendor.status}
                    </div>
                  </div>
                </div>
              </div>
            </tr>
          )
        })}
        </tbody>
      </table>
    )
  }

  return (
    <div id="home-page">
      <h2>My Data Sources</h2>
      <div className={"description"}>Share data from your medical devices with your clinical team.</div>
      {!useMobile() ? getVendorTable() : getMobileVendorTable()}

      {responseContent()}
      {errorContent()}
      {vendorRedirect()}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeConsentModal}
        contentLabel="Consent"
        className={useMobile() ? "mobile-consent-modal" : "consent-modal"}
        overlayClassName="ConsentModalOverlay"
        ariaHideApp={false}
        id={"consent-modal"}
      >
        {!processingModalResponse ? (
          <div className={"container fill d-flex flex-column modal-body justify-content-between"}>
            <div className={"container"}>
              <h2>{modalHeader}</h2>
              <div>
                {modalMessage}
              </div>
            </div>
            <div className={"row justify-content-between button-row"}>
              <div className={"col-sm-4 col-xs-12"}>
                <button className={"secondary-button"} onClick={closeConsentModal}>Cancel</button>
              </div>
              <div className={"col-sm-4 col-xs-12"}>
                <button className={"primary-button"} onClick={handleOkClick}>OK</button>
              </div>
            </div>
          </div>
        ) : (
          <div className={"modal-body fill d-flex justify-content-center align-items-center flex-column"}>
            <FontAwesomeIcon className="loading-icon" icon={faSpinner} size={"2x"} spin/>
            <div>{modalProcessingMessage}</div>
          </div>
        )}
      </Modal>
    </div>
  );
}
